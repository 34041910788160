$.fn.hasAttr = function(name) {
    return this.attr(name) !== undefined;
};

function isset ()
{
    // http://kevin.vanzonneveld.net
    // +   original by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
    // +   improved by: FremyCompany
    // +   improved by: Onno Marsman
    // +   improved by: Rafał Kukawski
    // *     example 1: isset( undefined, true);
    // *     returns 1: false
    // *     example 2: isset( 'Kevin van Zonneveld' );
    // *     returns 2: true

    var a = arguments,
        l = a.length,
        i = 0,
        undef;

    if (l === 0)
    {
        throw new Error('Empty isset');
    }

    while (i !== l)
    {
        if (a[i] === undef || a[i] === null)
        {
            return false;
        }
        i++;
    }
    return true;
}

function arrayHasOwnIndex(array, prop) {
    return array.hasOwnProperty(prop) && /^0$|^[1-9]\d*$/.test(prop) && prop <= 4294967294; // 2^32 - 2
}

function tryParseJSON (jsonString){
    try {
        var o = JSON.parse(jsonString);

        // Handle non-exception-throwing cases:
        // Neither JSON.parse(false) or JSON.parse(1234) throw errors, hence the type-checking,
        // but... JSON.parse(null) returns 'null', and typeof null === "object",
        // so we must check for that, too.
        if (o && typeof o === "object" && o !== null) {
            return o;
        }else{

        }
    }
    catch (e) { }

    return jsonString;
};

$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
});

function valuesToArray(obj) {
    var result = [];
    for (var key in obj) {
        if (obj.hasOwnProperty(key)) {
            result.push(obj[key]);
        }
    }
    return result;
}


$(document).ready(function(){
    var lastBtn;
    $(document).on('click', "button[type='button'], .btn", function () {
        var btn = $(this);

        if( btn.hasAttr('data-loading-text') ) {
            btn.button('loading');
        }

        if( btn.hasAttr('off-disable') ){
            return true;
        }
        if( btn.hasAttr('href') || btn.hasAttr('hrf') || btn.hasAttr('target') || btn.attr('data-toggle') == 'modal' || btn.hasClass('let_disable')  ) {
            lastBtn = btn;
            setTimeout(function () {
                btn.attr('disabled', 'disabled');
            }, 100);
        }
        return true;
    });

    $(document).on('click', "button[data-dismiss='modal']",function(){
        if(isset(lastBtn))
            lastBtn.removeAttr('disabled');

        if( $(this).hasAttr('reload'))
            self.location.reload();

        $('.modal-body').html('');
    });

    $(document).on('keydown', '.currency_input', function(e)
    {
        if(e.keyCode == 188)
        {
            e.preventDefault();
            $(this).val($(this).val() + '.');
        }
    });

    $('form').each(function() {  // attach to all form elements on page
        $(this).validate();
    });

    $('#page-form, .page-form').bind("keyup keypress", function(e) {
        if(  e.target.type != 'textarea' )
            return e.which !== 13;
    });

    $("#page-form, .page-form").submit(function(e) {
        var self = $(this);
        var btn = $('#form_submit');
        if( ! btn.length )
            btn = $(this).find('.form_submit:first');
        btn.attr('disabled', 'disabled');

        if( ! self.valid() ){
            btn.removeAttr('disabled');
            btn.button('reset');
        }
    });

    $('div.alert').not('.alert-important').delay(3000).slideUp(300);

    $('.btn-popover').popover({
        html: true
    });

    $('.search-select').on('change', function(){
        $.ajax({
            type: "POST",
            url: $(this).closest('form').prop( 'action' ),
            data: $(this).closest('form').serialize(),
            assync:false,
            cache:false,
            success: function( data ) {
                if(data == 'success') location.reload();
            }
        });
    });

    $('.autosearch-el').on('keyup', function(){
        var url = $('#autosearch-form').prop( 'action' );
        $.ajax({
            type: "POST",
            url: url,
            data: $('#autosearch-form').serialize(),
            assync: false,
            cache: false,
            dataType: 'html',
            success: function (data) {
                $('#main-content-container').html(data);
            }
        });
    });

    $(document).on('click', '.sidebar-toggle',function(){
       $.ajax({
         method:'POST',
         url:'/session/sidebar'
       });
    });


  /*  $(document).on('click', '.sidebar-toggle',function(){
        $.ajax({
            type: "POST",
            url: "/session/sidebar",
            data: $('#autosearch-form').serialize(),
            assync: false,
            cache: false,
            dataType: 'html',
            success: function (data) {
                $('#main-content-container').html(data);
            }
        });
    });
    */

    moment.locale('pl');

  /*  var pusher = new Pusher('194db8fae604fa0d68af');
    var channel = pusher.subscribe('vgp');

    channel.bind('report_parsed', function(data) {
        if(data.status == 'success')
        {
            $.notify({
                message: data.msg,
                icon: 'fa fa-check',
            },{
                type: 'success'
            });

            var pathname = document.location.pathname
            if(pathname == '/reports/index')
            {
                $('#modal .modal-content').html('<div class="modal-body" style="overflow:hidden;" id="dialog"><h3>Zakończono przetwarzanie raportu.</h3></div><div class="modal-footer"><a href="/reports/index" class="btn btn-primary">OK</a></div>');
                $('#modal').modal('show');
            }
        }else{
            $('#report_l_'+data.report_id).removeClass('label-warning');
            $('#report_l_'+data.report_id+' i').removeClass('fa-circle-o-notch fa-spin').addClass('fa-minus');
            setTimeout(function(){
                $('#report_l_'+data.report_id).addClass('label-danger');
                $('#report_l_'+data.report_id+' span').html('błąd przetwarzania');
            }, 200);

            $.notify({
                message: data.msg,
                icon: 'fa fa-exclamation-triangle',
            },{
                type: 'danger'
            });
        }
    });*/

    $('input.iCheck').iCheck({
        checkboxClass: 'icheckbox_minimal-blue',
        radioClass: 'icheckbox_minimal-blue',
        increaseArea: '20%' // optional
    });

    $('input.iCheck_line').each(function(){
        var self = $(this),
        label = self.next(),
        label_text = label.text();

        label.remove();
        self.iCheck({
            checkboxClass: 'icheckbox_line-blue',
            radioClass: 'iradio_line-blue',
            insert: '<div class="icheck_line-icon"></div>' + label_text
        });
    });

    $('.clear-search').on('click', function(){
        $('#'+$(this).data('clear')).val('');
        $(this).closest('form').submit();
    });


});

/*
 * Translated default messages for the jQuery validation plugin.
 * Language: PL
 */
jQuery.extend(jQuery.validator.messages, {
    required: "To pole jest wymagane.",
    remote: "Proszę o wypełnienie tego pola.",
    email: "Proszę o podanie prawidłowego adresu email.",
    url: "Proszę o podanie prawidłowego URL.",
    date: "Proszę o podanie prawidłowej daty.",
    dateISO: "Proszę o podanie prawidłowej daty (ISO).",
    number: "Proszę o podanie prawidłowej liczby.",
    digits: "Proszę o podanie samych cyfr.",
    creditcard: "Proszę o podanie prawidłowej karty kredytowej.",
    equalTo: "Proszę o podanie tej samej wartości ponownie.",
    accept: "Proszę o podanie wartości z prawidłowym rozszerzeniem.",
    maxlength: jQuery.validator.format("Proszę o podanie nie więcej niż {0} znaków."),
    minlength: jQuery.validator.format("Proszę o podanie przynajmniej {0} znaków."),
    rangelength: jQuery.validator.format("Proszę o podanie wartości o długości od {0} do {1} znaków."),
    range: jQuery.validator.format("Proszę o podanie wartości z przedziału od {0} do {1}."),
    max: jQuery.validator.format("Proszę o podanie wartości mniejszej bądź równej {0}."),
    min: jQuery.validator.format("Proszę o podanie wartości większej bądź równej {0}.")
});
